module.exports = [{
      plugin: require('/home/notplants/computer/projects/mfowler.info/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui"},
    },{
      plugin: require('/home/notplants/computer/projects/mfowler.info/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":750,"backgroundColor":"transparent"}}]},
    },{
      plugin: require('/home/notplants/computer/projects/mfowler.info/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
