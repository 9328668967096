// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-mdx-post-js": () => import("/home/notplants/computer/projects/mfowler.info/src/templates/mdx-post.js" /* webpackChunkName: "component---src-templates-mdx-post-js" */),
  "component---src-pages-404-js": () => import("/home/notplants/computer/projects/mfowler.info/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/notplants/computer/projects/mfowler.info/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/home/notplants/computer/projects/mfowler.info/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-feeds-js": () => import("/home/notplants/computer/projects/mfowler.info/src/pages/feeds.js" /* webpackChunkName: "component---src-pages-feeds-js" */),
  "component---src-pages-gitea-js": () => import("/home/notplants/computer/projects/mfowler.info/src/pages/gitea.js" /* webpackChunkName: "component---src-pages-gitea-js" */),
  "component---src-pages-index-js": () => import("/home/notplants/computer/projects/mfowler.info/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mail-js": () => import("/home/notplants/computer/projects/mfowler.info/src/pages/mail.js" /* webpackChunkName: "component---src-pages-mail-js" */),
  "component---src-pages-notes-js": () => import("/home/notplants/computer/projects/mfowler.info/src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-page-2-js": () => import("/home/notplants/computer/projects/mfowler.info/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

